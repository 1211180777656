import theme from './../../../gatsby-plugin-theme-ui';

// MEDIA
import CopyrightIcon from './../../../media/svg/copyright.svg';

export const link_styles = link => ({
    position: 'relative',
    width: '100%',
    fontFamily: theme.fonts.heading,
    fontSize: [ `calc(${theme.fontSizes[4]}px + 0.5rem)`, `calc(${theme.fontSizes[4]}px + 0.65rem)`, `calc(${theme.fontSizes[5]}px + 0.5rem)`],
    color: theme.colors.black,
    lineHeight: theme.lineHeights.heading,
    textAlign: 'center',
    textDecoration: 'none',
    margin: '1.5rem auto',
    ':after': {
        content: `"${link}"`,
        position: 'absolute',
        top: '50%',
        right: 0,
        left: 0,
        margin: '0 auto',
        fontSize: [`calc((${theme.fontSizes[4]}px + 0.2rem) * 1.5)`, `calc((${theme.fontSizes[4]}px + 0.2rem) * 1.5)`, `calc((${theme.fontSizes[5]}px + 0.2rem) * 1.5)`],
        color: theme.colors.grey,
        opacity: 0,
        transform: 'translateY(-35%)',
        transition: link_transition,
        zIndex: -1
    },
    ':hover:after': {
        opacity: 0.35,
        transform: 'translateY(-50%)',
        transition: link_transition
    },
    ':focus': {
        outline: 'none'
    }
});

export const footer_link_styles = {
    fontFamily: theme.fonts.body,
    color: theme.colors.black,
    fontSize: [14, 16, 18],
    ':focus': {
        outline: 'none'
    },
    textDecoration: 'none'
}

export const copyright_link_styles = {
    position: 'relative',
    ':after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: '-1rem',
        width: 12,
        height: 12,
        background: `url(${CopyrightIcon}) 100% 100% / cover no-repeat`
    }
}

const link_transition = `all 400ms ease-in-out 0.00001s`