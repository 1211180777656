import React from 'react'
import { Flex, Text } from 'rebass/styled-components';
import shortid from 'shortid';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import theme from './../../../gatsby-plugin-theme-ui';

// STYLES
import {
    navlinks_props,
    navlinks_container_props,
    border_props,
    link_props
} from './styles';

import { link_styles } from './../../atoms/link/styles';

// COMPONENTS
import Link from './../../atoms/link';
import Border from './../../atoms/border';

export default function NavLinks(props) {

    const { links, innerRef, closeMenu, activeMenu } = props;

    const offer_keys = [
        'Lista',
        'Wkłady kominkowe',
        'Piece kominkowe',
        'Kominki gazowe',
        'Kominki kaflowe',
        'Piece na pellet',
        'Biokominki',
        'Kuchnie kaflowe',
        'Kominki elektryczne',
        'Kominy lekkie',
        'Materiały wykończeniowe',
        'Materiały instalacyjne i montażowe',
        'Opał kominkowy'
    ]

    const offer_links = {
        'Lista': '/oferta',
        'Wkłady kominkowe': '/oferta/wklady-kominkowe',
        'Piece kominkowe': '/oferta/piece-kominkowe',
        'Kominki gazowe': '/oferta/kominki-gazowe',
        'Kominki kaflowe': '/oferta/kominki-kaflowe',
        'Piece na pellet': '/oferta/piece-na-pellet',
        'Biokominki': '/oferta/biokominki',
        'Kuchnie kaflowe': '/oferta/kuchnie-kaflowe',
        'Kominki elektryczne': '/oferta/kominki-elektryczne',
        'Kominy lekkie': '/oferta/kominy-lekkie',
        'Materiały wykończeniowe': '/oferta/materialy-wykonczeniowe',
        'Materiały instalacyjne i montażowe': '/oferta/materialy-instalacyjne-i-montazowe',
        'Opał kominkowy': '/oferta/opal-kominkowy'
    }

    const accordion_link_border_styles = (side, gradient) => ({
        position: 'absolute',
        top: side === 'top-up' || side === 'top-down' ? 0 : 'auto',
        bottom: side === 'bottom-up' || side === 'bottom-down' ? 0 : 'auto',
        left: side === 'bottom-up' || side === 'bottom-down' ? 0 : 'auto',
        right: side === 'top-up' || side === 'top-down' ? 0 : 'auto',
        width: side === 'bottom-down' || side === 'top-down' ? 3 : 60,
        height: side === 'top-up' || side === 'bottom-up' ? 3 : 30,
        background: `linear-gradient(${gradient}, #FFF, ${theme.colors.red})`
    })

    const link_list = links.map(link => {
        if(link === 'Oferta') {
            return <Accordion key={shortid.generate()} allowMultipleExpanded={true} allowZeroExpanded={true}>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton style={{ outline: 'none', cursor: 'pointer' }}>
                            <Text sx={link_styles('Oferta')}>{link}</Text>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    {
                        offer_keys.map(key => (
                            <AccordionItemPanel key={shortid.generate()} style={{
                                position: 'relative',
                                maxWidth: 280,
                                margin: '2rem auto',
                                fontSize: 22,
                                textAlign: 'center',
                                padding: '2rem 1rem'
                            }}>
                                <Link accordion={{
                                    position: 'relative',
                                    color: theme.colors.black,
                                    textDecoration: 'none',
                                    fontFamily: theme.fonts.body,
                                }} to={offer_links[`${key}`]} onClick={closeMenu}>
                                    {key}
                                </Link>
                                <div style={accordion_link_border_styles('top-up', 'to right')}></div>
                                <div style={accordion_link_border_styles('top-down', 'to top')}></div>
                                <div style={accordion_link_border_styles('bottom-up', 'to left')}></div>
                                <div style={accordion_link_border_styles('bottom-down', 'to bottom')}></div>
                            </AccordionItemPanel>
                        ))
                    }
                </AccordionItem>
            </Accordion>
        } else {
            return <Link {...link_props(link, shortid, closeMenu, activeMenu)}>{link}</Link>
        }
    });

    return (
        <Flex {...navlinks_props}>
            <Border {...border_props} />
            <Flex ref={innerRef} {...navlinks_container_props}>
                {link_list}
            </Flex>
        </Flex>
    )
}
