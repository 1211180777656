import React from 'react'
import { Flex } from 'rebass/styled-components';
import shortid from 'shortid';

// STYLES
import {
    footer_links_props,
    footer_links_container_props,
    links_props,
    copyright_props
} from './styles';

// COMPONENTS
import Link from './../../atoms/link';

export default function FooterLinks(props) {

    const { links } = props;

    return (
        <Flex {...footer_links_props}>
            <Flex {...footer_links_container_props}>
                {
                    links.map(link => 
                    <Link {...links_props(link, shortid)}>{link === 'Faq' ? link.toUpperCase() : link}</Link>
                    )
                }
            </Flex>
            <Link {...copyright_props}>Ardor 2019</Link>
        </Flex>
    )
}
