import theme from './../../../gatsby-plugin-theme-ui';

export const heading_styles = (text, margin, padding, fontSize, fontWeight, textAlign, color, float, maxWidth) => ({
    position: 'relative',
    display: 'table',
    fontFamily: theme.fonts.heading,
    fontSize: fontSize ? fontSize : [24, 28],
    color: color ? color : theme.colors.black,
    fontWeight: fontWeight ? fontWeight : theme.fontWeights.heading,
    lineHeight: theme.lineHeights.heading,
    textAlign: textAlign ? textAlign : 'center',
    margin: margin ? margin : '0 auto',
    padding: padding ? padding : null,
    float,
    maxWidth,
    ':after': text ? {
        content: `"${text}"`,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        left: ['0.5rem', '2rem'],
        fontSize: [`calc((${fontSize ? fontSize[0] : 22}px + 0.2rem) * 1.5)`, `calc((${fontSize ? fontSize[1] : 24}px + 0.2rem) * 1.5)`],
        color: theme.colors.grey,
        opacity: 0.35,
        zIndex: -1
    } : null
})