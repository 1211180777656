// PROPS

export const footer_info_props = {
    as: 'section',
    sx: {
        flexFlow: ['column nowrap', 'column nowrap', 'row nowrap'],
        justifyContent: ['center', 'center', 'space-around'],
        alignItems: 'center',
        width: '100%',
        maxWidth: 1024,
        margin: '1rem auto'
    }
}

export const info_container_props = {
    flexFlow: 'column nowrap',
    margin: ['0.5rem auto', '0.5rem auto', 0],
    minWidth: ['auto', 'auto', '40%'],
    justifyContent: 'center'
}

export const heading_info_props = {
    as: 'h2',
    fontWeight: '700',
    fontSize: [18, 20, 22],
    margin: '1rem auto'
}

export const text_info_props = {
    as: 'p',
    fontSize: [14, 16, 18],
    margin: '0.5rem auto',
    textAlign: 'center'
}