import React from 'react'
import { Flex, Box, Text } from 'rebass/styled-components';

// STYLES
import { hamburger_container_styles, label_styles, rotate_icon_container_styles, rotate_icon_box_styles } from './styles';

export default function Hamburger(props) {

    const { content, onClick, width, height, close_btn, color } = props;

    const close_btn_position = close_btn ? {
        position: 'absolute',
        right: '1.25rem'
    } : null;

    return (
        <Flex sx={{...hamburger_container_styles(width, height), ...close_btn_position}} onClick={onClick}>
            <Text as='p' sx={label_styles(color)}>{content}</Text>
            <Box sx={rotate_icon_container_styles}>
                <Box sx={{
                    ...rotate_icon_box_styles,
                    top: 0,
                    left: 0
                }}></Box>
                <Box sx={{
                    ...rotate_icon_box_styles,
                    top: 0,
                    right: 0
                }}></Box>
                <Box sx={{
                    ...rotate_icon_box_styles,
                    bottom: 0,
                    left: 0
                }}></Box>
                <Box sx={{
                    ...rotate_icon_box_styles,
                    bottom: 0,
                    right: 0
                }}></Box>
            </Box>
        </Flex>
    )
}