import theme from './../../../gatsby-plugin-theme-ui';

export const menu_props = {
    as: 'aside',
    sx: {
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        background: `rgba(${theme.colors.lightgrey_rgba}, 0.65)`,
        zIndex: 1000,
        visibility: 'hidden',
        opacity: 0
    },
}

export const icon_container_props = {
    sx: {
        maxWidth: 450,
        width: 'auto',
        height: [104, 104, 144],
        position: 'absolute',
        top: 0,
        right: 0,
        padding: '0 1rem',
        alignItems: 'center',
        zIndex: 1002
    }
}

export const close_icon_props = (calculate, activeMenu, closeMenu, openMenu) => ({
    width: calculate(78),
    height: calculate(48),
    close_btn: true,
    onClick: () => activeMenu ? closeMenu() : openMenu(),
    content: 'Zamknij'
})

export const nav_links_props = closeMenu => ({
    closeMenu,
    links: ['O firmie', 'Oferta', 'Projekty', 'Promocje', 'Kontakt']
})