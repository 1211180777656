// PROPS

export const footer_links_props = {
    as: 'nav',
    sx: {
        width: ['100%', 'calc(100% - (1.25rem * 2))'],
        maxWidth: 1024,
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: [0, '0 auto']
    }
}

export const footer_links_container_props = {
    justifyContent: 'space-between',
    sx: {
        '> a:last-child': {
            margin: '0 0 0 1.25rem'
        }
    }
}

export const links_props = (link, shortid)=> ({
    key: shortid.generate(),
    to: link === 'Prywatność' ? '/prywatnosc' : `/${link.charAt(0).toLowerCase() + link.slice(1, )}/`,
    footer: true
})

export const copyright_props = {
    to: '/',
    footer: true,
    copyright: true
}