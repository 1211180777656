import React from 'react'
import { Flex } from 'rebass/styled-components';

// STYLES
import {
    footer_info_props,
    info_container_props,
    heading_info_props,
    text_info_props
} from './styles';

// COMPONENTS
import Text from './../../atoms/text';
import Heading from './../../atoms/heading';

export default function FooterInfo() {

    return (
        <Flex {...footer_info_props}>
            <Flex sx={info_container_props}>
                <Text {...text_info_props}>Kaja - 502 673 505</Text>
            </Flex>
            <Flex sx={info_container_props}>
                <Text {...text_info_props}>ul. Szosa Szubińska 35 86-005 Białe Błota</Text>
                <Text {...text_info_props}>Godziny otwarcia - Pn-Pt 10-17, Sob 10-13</Text>
            </Flex>
        </Flex>
    )
}
