export const header_props = height => ({
    as: 'header',
    sx: {
        height,
        justifyContent: 'center',
        alignItems: 'center'
    }
});

export const nav_props = (activeMenu, closeMenu, openMenu) => ({
    activeMenu, closeMenu, openMenu
})