import React, { useRef, useEffect } from 'react'
import { Flex, Box } from 'rebass/styled-components';
import { TimelineMax, TweenMax } from 'gsap';

// STYLES
import {
    menu_props,
    icon_container_props,
    close_icon_props,
    nav_links_props
} from './styles';

// COMPONENTS
import NavLinks from './../../molecules/navlinks';
import Hamburger from './../../atoms/hamburger';
import { calculation_dimension }from './../../molecules/navigation/styles';

export default function Menu(props) {

    const { activeMenu, closeMenu, openMenu } = props;

    let overlay_container_ref = useRef();
    let icon_container_ref = useRef();
    let navlinks_ref = useRef();
    let tl = useRef();
    let tween = useRef();

    useEffect(() => {

        tl.current = new TimelineMax({ paused: true });
        tween.current = TweenMax;

        const overlay_animation = tween.current.to(
            overlay_container_ref.current,
            0.4, { opacity: 1,
            autoAlpha: 1 }
        );

        const nav_container_animation = tween.current.to(
            navlinks_ref.current.parentElement,
            0.45, { x: 0, opacity: 1 }
        )

        tl.current.add(overlay_animation);
        tl.current.add(nav_container_animation);
    }, [])

    useEffect(() => {

        if (activeMenu) {
            tl.current.play();
        } else {
            tl.current.reversed( !tl.current.reversed() );
        }

    }, [activeMenu])

    return (
        <Flex ref={overlay_container_ref} {...menu_props}>
            <Box sx={{
                position: 'fixed',
                left: 0,
                top: 0,
                width: `calc(100% - 450px)`,
                height: '100%',
                cursor: 'pointer'
            }} onClick={() => closeMenu()}></Box>
            <Flex ref={icon_container_ref} {...icon_container_props}>
                <Hamburger {...close_icon_props(
                    calculation_dimension, activeMenu, closeMenu, openMenu
                )} />
            </Flex>
            <NavLinks innerRef={navlinks_ref} {...nav_links_props(closeMenu)} activeMenu={activeMenu} />
        </Flex>
    )
}