import React, { useRef, useEffect } from 'react';
import { Flex, Image } from 'rebass/styled-components';
import { TweenMax } from 'gsap';

// MEDIA
import Arrow from './../../../media/svg/arrow_up.svg'

export default function ScrollToTop(props) {

  const { hasScrolled, scrollTop } = props;

  // REFS
  let icon_container_ref = useRef();
  let tween = useRef();

  useEffect(() => {

    tween.current = TweenMax;

    const icon_container_animation = tween.current.to(
        icon_container_ref.current, 0.4, { opacity: hasScrolled ? 0.9 : 0, autoAlpha: hasScrolled ? 1 : 0 }
    )

    icon_container_animation.play();

  }, [hasScrolled])

  return (
    <Flex ref={icon_container_ref} as='button' sx={{
        position: 'fixed',
        bottom: '1.25rem', right: '1.05rem',
        background: 'linear-gradient(180deg, #97021B 27.6%, #15100F 100%)',
        width: 45,
        height: 45,
        border: 'none',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
        opacity: 0,
        cursor: 'pointer',
        visibility: 'hidden',
        boxShadow: '0px 4px 8px rgba(0,0,0,0.05)',
        ':focus': {
            outline: 'none'
        }
        }} onClick={() => scrollTop()}>
        <Image width='auto' height='60%' src={Arrow} alt='arrow_up.svg' />
    </Flex>
  );
}
