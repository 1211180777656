import React from 'react';
import { Button as RebassButton } from 'rebass/styled-components';
import theme from './../../../gatsby-plugin-theme-ui';

export default function Button(props) {

  const { children, position, onClick, fontSize, padding, margin, styles, anchor} = props;

  return (
    <RebassButton {...anchor} sx={{
        ...position,
        border: 'none',
        background: 'transparent',
        cursor: 'pointer',
        fontFamily: theme.fonts.body,
        fontSize: fontSize ? fontSize : [16, 18, 18],
        color: theme.colors.black,
        padding,
        margin,
        lineHeight: '1rem',
        fontWeight: 700,
        ...styles,
        ':focus': {
            outline: 'none'
        }
    }} onClick={onClick}>{children}</RebassButton>
  );
}
