export const vector_styles = (width, height, maxWidth, margin) => ({
    width: width ? width : 'auto',
    height: height ? height : 'auto',
    maxWidth,
    margin,
    padding: '0.25rem',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    ':focus': {
        outline: 'none'
    }
});