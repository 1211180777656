// PROPS

export const navigation_props = {
    as: 'nav',
    sx: {
        width: '100%',
        maxWidth: 1024,
        height: 'auto',
        margin: '0 auto',
        padding: '0 1.25rem',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}

export const logo_props = (navigate, logo) => ({
    height: [calculation_dimension(48), calculation_dimension(56), calculation_dimension(64)],
    Icon: logo,
    alt: 'logo',
    onClick: () => navigate('/')
})

export const hamburger_props = (activeMenu, closeMenu, openMenu) => ({
    content: 'menu',
    width: calculation_dimension(62),
    height: calculation_dimension(48),
    onClick: () => activeMenu ? closeMenu() : openMenu()
})

// HELPERS
export const calculation_dimension = dimension => `calc(${dimension}px + (0.25rem * 2))`