import theme from './../../../gatsby-plugin-theme-ui';

// PROPS

const flexFlow = 'column nowrap';

export const navlinks_props = {
    as: 'nav',
    sx: {
        flexFlow,
        position: 'fixed',
        top: 0,
        right: 0,
        padding: 0,
        maxWidth: 450,
        width: '100%',
        height: '100vh',
        background: '#FFF',
        boxShadow: '0 8px 10px rgba(0,0,0,0.15), 0 4px 8px rgba(0,0,0,0.22)',
        overflow: 'hidden',
        paddingBottom: '1rem',
        zIndex: 1001,
        transform: 'translateX(100%)',
        opacity: 0
    }
}

export const navlinks_container_props = {
    sx: {
        flexFlow,
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingTop: ['20vh', `calc(${104}px + 2rem)`, `calc(${144}px + 2rem)`],
    }
}

export const border_props = {
    position: { top: 0, left: '1.25rem' },
    width: 10,
    height: '45%',
    transform: { transform: 'rotate(180deg)' }
}

export const link_props = (link, shortid, closeMenu, activeMenu) => ({
    to: `/${link.charAt(0).toLowerCase() + link.slice(1, ).replace(' ', '-')}/`,
    key: shortid.generate(),
    onClick: closeMenu,
    activeStyle: { color: theme.colors.grey }
})