import React from 'react'
import { Flex } from 'rebass/styled-components';

// COMPONENTS
import Navigation from './../../molecules/navigation';

// STYLES
import { header_props, nav_props } from './styles';

export default function Header(props) {

    const { activeMenu, closeMenu, openMenu } = props;

    return (
        <Flex {...header_props([104, 104, 144])}
        data-sal='fade'
        data-sal-duration="800"
        data-sal-delay="0"
        data-sal-easing="cubic-bezier(0.165, 0.84, 0.44, 1)">
                <Navigation {...nav_props(activeMenu, closeMenu, openMenu)} />
        </Flex>
    )
}
