export default {
  breakpoints: [480, 768, 992, 1200],
  fontSizes: [
    12, 14, 16, 20, 24, 32, 48, 64
  ],
  colors: {
    black: '#15100F',
    red: '#6E2F3A',
    grey: '#D7CDCC',
    lightgrey: '#F0F3F5',
    lightgrey_rgba: `240,243,245`,
    green: '#2B4141'
  },
  space: [
    0, 4, 8, 16, 32, 64, 128, 256
  ],
  fonts: {
    body: `'Nunito', sans-serif`,
    heading: `'Quicksand', sans-serif`,
  },
  fontWeights: {
    body: 300,
    heading: 400,
    bold: 700,
  },
  lineHeights: {
    body: '2rem',
    heading: '2rem',
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)'
  }
}