// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-offer-details-index-js": () => import("./../../../src/components/templates/offerDetails/index.js" /* webpackChunkName: "component---src-components-templates-offer-details-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-firmie-js": () => import("./../../../src/pages/o-firmie.js" /* webpackChunkName: "component---src-pages-o-firmie-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-projekty-js": () => import("./../../../src/pages/projekty.js" /* webpackChunkName: "component---src-pages-projekty-js" */),
  "component---src-pages-promocje-js": () => import("./../../../src/pages/promocje.js" /* webpackChunkName: "component---src-pages-promocje-js" */),
  "component---src-pages-prywatnosc-js": () => import("./../../../src/pages/prywatnosc.js" /* webpackChunkName: "component---src-pages-prywatnosc-js" */)
}

