import React from 'react'
import { Text as RebassText } from 'rebass/styled-components';

// STYLES
import { text_styles } from './styles';

export default function Text(props) {

    const { children, fontSize, as, margin, padding, onClick, 
        buttonStyles, width, fontWeight, textAlign, dangerouslySetInnerHTML, lineHeight,
        animation } = props;

    return (
        <RebassText as={as} 
        sx={{ 
            ...text_styles(
                fontSize, fontWeight, 
                margin, padding, width, 
                textAlign, buttonStyles,
                lineHeight
            )
        }} onClick={onClick} dangerouslySetInnerHTML={dangerouslySetInnerHTML} {...animation}>
            {children}
        </RebassText>
    )
}
