// PROPS

export const footer_props = path => ({
    as: 'footer',
    sx: {
        position: 'relative',
        flexFlow: 'column nowrap',
        minHeight: path ? 'auto' : [650, 650, 515],
        background: `linear-gradient(180deg, rgba(242, 244, 246, 0) 0%, #F2F4F6 100%)`,
        padding: '1.25rem'
    }
})

export const footer_vector_props = icon => ({
    width: '100%',
    height: '100%',
    Icon: icon
})

export const footer_heading_props = {
    as: 'h1',
    background_text: 'Kontakt'
}

export const footer_email_link_props = {
    fontSize: [18, 20, 22],
    textDecoration: 'none',
    textAlign: 'center',
    margin: '3.5rem auto 0.5rem auto'
}

export const footer_fb_link_props = {
    sx: {
        width: [48, 48, 58], height: [48, 48, 58], margin: '2rem auto'
    },
    href: 'https://www.facebook.com/ardor.kominki/',
    target: '_blank',
    rel: 'noopener'
}