import theme from './../../../gatsby-plugin-theme-ui';

export const hamburger_container_styles = (width, height) => ({
    minWidth: width ? width : '78px',
    minHeight: height ? height : '48px',
    padding: '0.25rem',
    cursor: 'pointer',
    justifyContent: 'space-between',
    alignItems: 'center',
    ':focus': {
        outline: 'none'
    },
    ':hover > div': {
        transform: 'rotate(180deg)',
        transition: 'transform 400ms ease-in-out 0.0000001s'
    } 
});

export const label_styles = color => ({
    fontFamily: theme.fonts.body,
    fontWeight: theme.fontWeights.heading,
    fontSize: [16, 18, 20],
    color: color ? color : theme.colors.black,
    marginRight: '1rem'
});

export const rotate_icon_container_styles = {
    position: 'relative',
    width: 16,
    height: 16,
    transform: 'rotate(0)',
    transition: 'transform 400ms ease-in-out 0.0000001s',
};

export const rotate_icon_box_styles = {
    position: 'absolute',
    width: 6,
    height: 6,
    borderRadius: '1.5px',
    background: theme.colors.red
};