import React from 'react'
import { Link as GatsbyLink } from 'gatsby';
import { Link as RebassLink } from 'rebass/styled-components';
import theme from './../../../gatsby-plugin-theme-ui';

// STYLES
import { link_styles, footer_link_styles, copyright_link_styles } from './styles';

export default function Link(props) {

    const { to, activeStyle, children, onClick, footer, copyright, accordion } = props;

    const linkStyles = footer ? copyright ? { ...footer_link_styles, ...copyright_link_styles } : footer_link_styles : link_styles(children);

    return (
        <RebassLink as={GatsbyLink} sx={accordion ? { ...accordion } : {...linkStyles}} to={to} activeStyle={activeStyle} onClick={onClick}>{children}</RebassLink>
    )
}