import React from 'react'
import { Flex, Link as RebassLink } from 'rebass/styled-components';

// MEDIA
import FacebookIcon from './../../../media/svg/facebook.inline.svg';

// STYLES
import {
    footer_props,
    footer_heading_props,
    footer_email_link_props,
    footer_fb_link_props,
    footer_vector_props
} from './styles';
import { text_styles } from './../../atoms/text/styles';

// COMPONENTS
import Heading from './../../atoms/heading';
import Vector from './../../atoms/vector';
import FooterInfo from './../../molecules/footerInfo';
import FooterLinks from './../../molecules/footerLinks';

export default function Footer(props) {

    return (
        <>
        {
            props.path.includes('kontakt') ? 
            <Flex {...footer_props(props.path)}
            data-sal='fade'
            data-sal-duration="800"
            data-sal-delay="0"
            data-sal-easing="cubic-bezier(0.165, 0.84, 0.44, 1)">
                <FooterLinks links={['Prywatność', 'Faq']} />
            </Flex> :
            <Flex {...footer_props(props.path)}
            data-sal='fade'
            data-sal-duration="800"
            data-sal-delay="0"
            data-sal-easing="cubic-bezier(0.165, 0.84, 0.44, 1)">
                <Heading {...footer_heading_props}>Skontaktuj się z nami</Heading>
                <RebassLink sx={{ ...text_styles(), ...footer_email_link_props }} 
                href='mailto: kominki.ardor@gmail.com'>kominki.ardor@gmail.com</RebassLink>
                <FooterInfo />
                <RebassLink {...footer_fb_link_props}><Vector {...footer_vector_props(FacebookIcon)} /></RebassLink>
                <FooterLinks links={['Prywatność', 'Faq']} />
            </Flex>
        }
        </>
    )
}
