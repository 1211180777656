import React from 'react'
import { Heading as RebassHeading } from 'rebass/styled-components';

// STYLES
import { heading_styles } from './styles';

export default function Heading(props) {

    const { as, children, background_text, fontSize, margin, fontWeight, 
        textAlign, padding, color, float, onClick, style, maxWidth } = props;

    return (
        <RebassHeading as={as} sx={{...heading_styles(background_text, margin, 
        padding, fontSize, fontWeight, textAlign, color, float, maxWidth)}}
        style={style} onClick={onClick}>
            {children}
        </RebassHeading>
    )
}
