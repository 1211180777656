import React from 'react'
import { Flex } from 'rebass/styled-components';
import { navigate } from 'gatsby';

// MEDIA
import logo from './../../../media/svg/ardor_logo.svg';

// COMPONENTS
import Vector from './../../atoms/vector';
import Hamburger from './../../atoms/hamburger';

// STYLES
import { navigation_props, logo_props, hamburger_props  } from './styles';

export default function Navigation(props) {

    const { activeMenu, closeMenu, openMenu } = props;

    return (
        <Flex {...navigation_props}>
            <Vector {...logo_props(navigate, logo)} />
            <Hamburger {...hamburger_props(activeMenu, closeMenu, openMenu)} />
        </Flex>
    )
}