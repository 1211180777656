import React from 'react';

// COMPONENTS
import Modal from '../../templates/offerDetails/modal';

export default function CookiesInfo(props) {

    const { status, onAccept, onReadMore } = props;

    return (
        <Modal status={status} onClick={onAccept} onReadMore={onReadMore}
        title='Ciasteczka' text='Ta strona używa plików Cookies. 
        Dowiedz się więcej o celu ich używania i 
        możliwości zmiany ustawień Cookies w przeglądarce.' button accept />
    );
}
