import theme from './../../../gatsby-plugin-theme-ui';

export const text_styles = (fontSize, fontWeight, margin, padding, width, textAlign, buttonStyles, lineHeight) => ({
    fontFamily: theme.fonts.body,
    fontSize: fontSize ? fontSize : [16, 16, 18],
    fontWeight: fontWeight ? fontWeight : theme.fontWeights.body,
    color: theme.colors.black,
    lineHeight: lineHeight ? lineHeight : theme.lineHeights.body,
    margin,
    padding,
    width,
    textAlign,
    ...buttonStyles
})