import React from 'react'
import { Box } from 'rebass/styled-components';

// STYLES
import { border_styles } from './styles';

export default function Border(props) {

    const { position, transform, width, height, full, display, relative, zIndex, background, serviceAnimation } = props;

    return (
        <Box sx={{
            ...border_styles(width, height, full, relative, background),
            ...position,
            ...transform,
            ...display,
            zIndex
        }}
        data-sal={ serviceAnimation ? 'fade' : null }
        data-sal-duration="600"
        data-sal-delay="0"
        data-sal-easing="cubic-bezier(0.165, 0.84, 0.44, 1)">
            
        </Box>
    )
}
