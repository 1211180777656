import React from 'react'
import { Flex } from 'rebass/styled-components';

// STYLES
import { vector_styles } from './styles';

export default function Vector(props) {

    const { width, height, Icon, alt, onClick, close_btn, style, maxWidth, margin } = props;

    const close_btn_position = close_btn ? {
        position: 'absolute',
        right: '1.25rem'
    } : null

    return (
        <>
            {
                typeof Icon !== 'function' ?
                <Flex
                as='img'
                sx={vector_styles(width, height, maxWidth)}
                src={Icon}
                alt={alt}
                onClick={onClick}></Flex> : 
                <Flex sx={{
                    ...vector_styles(null, height),
                    ...close_btn_position,
                    ...style
                }} width={width} onClick={onClick}>
                    <Icon />
                </Flex>
            }
        </>
    )
}
